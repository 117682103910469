.mobile_logo{
    height:25px;
}

.botton-nav-icon{
}

body,a,li{
    font-family: 'Inter', sans-serif;
}

.navbar-brand{
    margin-right: 0;
}

.dark-card-player{
    background-color: #101A20;
    border: none!important;
    padding: 10px;
    border-radius: 10px;
}

.players-table thead{
    border-radius: 5px;
}

.players-table{
    padding:5px;
}

.player-row{
    background-color: #293C4B!important;
    padding: 5px;
}

.player-row:nth-child(even){
    background-color: #18222A!important;
}

.player-row:nth-child(odd){
    background-color: transparent!important;
}

.content-row:nth-child(even){
    background-color: #18222A!important;
}

.content-row:nth-child(odd){
    background-color: transparent!important;
}

.players-table thead tr th{
    background: #2C3740;
    border: none;

}

.nav-tabs{
    background: #314E54;
    border-radius: 5px;
}

.nav-tabs .nav-item.show .nav-link, .nav-tabs .nav-link.active{
    background: #101A20!important;
    mix-blend-mode: normal;
    border-radius: 5px;
    font-weight: 700;
    color: #F49322;
}

.on-win .nav-tabs .nav-link{
    font-size: 10!important;
}


.bet-container{
    background: #304F67;
}

.bet-container-input{
    background: transparent;
    padding:3px 15px;
    margin: 10px;
    color:  #A7BCCA;
    border: none;
    font-weight: 700;
}

.reset-btn{
    background: #263238;
    mix-blend-mode: normal;
    border-radius: 2px;
    border: none;
    color: #F2B90C;
    padding: 5px 15px;
}

.increase-btn{
    background: #263238;
    mix-blend-mode: normal;
    border-radius: 2px;
    border: none;
    color: #F2B90C;
    padding: 5px 15px;
}

.bet-amount-bg{
    color: white;
    background: transparent;
    padding: 5px;
    font-weight: 700;
    border-right: 1px solid #F2B90C;
    font-family: Inter;
}
.bet-amount-bg::after{
    height: 60%;
}

.bet-amount-input::placeholder{
    color: white;
}

.transparent-bg{
    background-color: transparent;
}

.bet-amount-input{
    background: #202E38;
    border-radius: 5px;
    color: white;
    transition: border-color 0.15s ease-in-out;
}

.bet-amount-input::-webkit-input-placeholder{
    color: rgb(190, 190, 190)!important;
}

.bet-amount-input:-moz-placeholder {
    color:rgb(190, 190, 190)!important;
}

.bet-amount-input:hover{
    background: #303f47;
    color: white;
    transition: border-color 0.15s ease-in-out;
}

::placeholder{
    color: white;
}

.tabs-container{
    padding:6px 6px;
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
}

.nav-tabs .nav-link{
    background-color: transparent;
    color: white;
    padding:0.5rem 0.4rem;
    font-size: 12px!important;
    font-weight: 500;
}

#classic.nav-tabs.nav-link.active{
    background: #2C3740;
    mix-blend-mode: normal;
    border-radius: 3px;
    color: #F49322;
}

.classic-tabs .nav-link.active{
    background: #2C3740!important;
    mix-blend-mode: normal;
    border-radius: 3px;
    color: #F49322!important;
}

.classic-tabs .nav-link{
    padding:5px 5px;
}


.classic-tabs{
    padding: 3px 4px;
    border-radius: 5px;
    font-size: 11px!important;
    background: #1C252C;
    border: 1px solid #2A3945;
    border-radius: 5px;
}


.login-btn{
    background: #F49322;
    border-radius: 5px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 11px;
    line-height: 24px;
    padding:0px 12px;
}

.notif-card-1{
    background: linear-gradient(270deg, #F49322 0%, rgba(86, 23, 124, 0.33) 113.56%);
    border-radius: 10px;
    padding:5px 5px;
}
.hr-margin{
    margin-top: 10px;
    margin-bottom: 10px;
}


.notif-card-2{
    background: linear-gradient(89.98deg, #06578C 13.91%, #0D1D27 100.48%);
    border-radius: 10px;
    padding:5px 5px;
}

.notif-play-btn{
    background: #1BB55C;
    border-radius: 5px;
    padding:2px 8px;
    font-size: 11px;
    border: none;
    color: white;
}

.notif-hr{
    border: 1px solid #2A3339!important;
}

.notif-body{
    padding-top:10px;
    padding-bottom:10px;
}

.remove-side-padding{
    padding-left: 0;
    padding-right: 0;
}

.padding-2rem{
    padding-top: 1rem!important;
}

.special-card{
    border-radius: 10px!important;
    background-color: #304F67;
    padding: 5px!important;
    margin: 5px 5px;
}

.special-red-btn{
    background: #E84A4A;
    mix-blend-mode: normal;
    border-radius: 6px;
    color: white;
    padding: 5px;
    font-weight: 500;
    border: none!important;
}
.special-red-btn:hover{
    background: #830c0c;
    mix-blend-mode: normal;
    border-radius: 6px;
    color: white;
    padding: 5px;
    font-weight: 500;
    border: none!important;
}

.special-green-btn{
    background: #1BB55C;
    mix-blend-mode: normal;
    border-radius: 6px;
    color: white;
    padding: 5px;
    font-weight: 500;
    border: none!important;
}
.special-green-btn:hover{
    background: #158845;
    mix-blend-mode: normal;
    border-radius: 6px;
    color: white;
    padding: 5px;
    font-weight: 500;
    border: none!important;
}
.special-moon-btn{
    background: #F2B90C;
    mix-blend-mode: normal;
    border-radius: 6px;
    color: white;
    padding: 5px;
    font-weight: 500;
    border: none!important;
}
.special-moon-btn:hover{
    background: #b68c0c;
    mix-blend-mode: normal;
    border-radius: 6px;
    color: white;
    padding: 5px;
    font-weight: 500;
    border: none!important;
}

.hr-margin-2{
    margin-top: 4px;
    margin-bottom: 4px;
}
.margin-0{
    margin-bottom: 0!important;
}

.placeholder-txt::placeholder { /* Most modern browsers support this now. */
        color: #A7BCCA!important;
        font-weight: 500;
     }

.rnc__notification-item--warning{
    background: rgba(31, 53, 69, 0.89);
    backdrop-filter: blur(2px);
    /* Note: backdrop-filter has minimal browser support */
    font-family: Inter;
    border-radius: 5px;
    border: none;
    margin:30px;
}

.message-color{
    color: #F2B90C!important;
}
.bottom-menu-icon{
    font-size: 16px;
}

.bottom-items:hover{
    color: #F49322!important;
}

.font-9{
    font-size: 9px!important;
}

.bottom-tab-label{
    padding-top: 3px;
}
.orange-btn{
    background: #F49322;
    border-radius: 5px;
    padding: 13px 20px;
    border: none;
    width: 100%;
    color: #000;
    font-weight: 500;
    font-size: 13px;
    text-transform: capitalize;
    font-family: Inter;
    font-weight: 600;
}

.grey-btn{
    background: #304F67;
    border-radius: 5px;
    padding: 13px 20px;
    border: none;
    width: 100%;
    color: #fff;
    font-weight: 500;
    font-size: 13px;
    text-transform: capitalize;
    font-family: Inter;
    font-weight: 300;
}

.orange-btn:hover{
    background: #cf7c1d;
}

.gray-text{
    color: #A7BCCA!important;
    font-family: 'inter'!important;
}


.register-btn{
    background: #0B4664;
    border-radius: 5px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 11px;
    line-height: 24px;
    padding:0px 12px;
    margin-right: 8px;
}
.mobile-topbar{
    padding: 12px 9px!important;
    background-color: #27343E;
    margin-left:-15px;
    margin-right:-15px;
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
}

.desktop-topbar{
    padding: 10px 9px!important;
    background-color: #27343E;
    margin-left:-10px;
    margin-right:-10px;
}

.pad-left-30{
    padding-left: 30px;
}

.manual-auto-bar{
    position: absolute;
    bottom: 0;
    width: 100%;
}

.top-10{
    padding-top:10px
}

.margin-bottom-3{
    margin-bottom: 4rem;
}

.game-card{
    background: #101A20;
    border-radius: 10px;
    padding:5px;
}

.padding-15{
    padding:5px 15px
}

.padding-10{
    padding: 10px 0px;
}

.remove-margin{
    margin-left: 0px;
    margin-right: 0px;
}

.main-special-table{
    border-collapse:separate;
    border-spacing:0 5px;
}

.special-table{
    background: #202E38;
    border-radius: 5px;
    margin: 5px 0px;
    
}

.special-table td:first-child{
    border-radius: 5px 0px 0px 5px;
}

.special-table td:last-child{
    border-radius: 0px 5px 5px 0px;
}

.special-table tr.spaceUnder>td {
    padding-bottom: 1em;
  }


.dark-card{
    background: #101A20;
    border-radius: 10px;
    padding:15px;
    padding-top: 20px;
    border: none!important;
}

.stats-dark-card{
    background: #263238;
    border-radius: 10px;
    padding:15px;
    padding-top: 20px;
    border: none!important;
}

.inner-dark-bg{
    background: transparent;
    border: none!important;
}

.online-dot{
    color:#00FF47;
    font-size:10px;
}

.crash-history {
    overflow: hidden;
    width: 100%;
}

.crash-history .text-danger {
    background-color: #E84A4A !important;
    font-weight: 700;
    color: #fff!important;
    padding: 3px 6px;
    border-radius: 10px;
    font-size: 10px!important;
}

.crash-history .text-success {
    color: #fff !important;
    font-weight: 700;
    background-color: #1BB55C;
    padding: 3px 6px;
    border-radius: 10px;
    font-size: 10px!important;
}
.crash-history .text-moon {
    color: #fff !important;
    font-weight: 700;
    background-color: #F49322;
    padding: 3px 6px;
    border-radius: 10px;
    font-size: 10px!important;
}

.text-main {
    background-color: #1C3A51 !important;
    font-weight: 700;
    color: #fff!important;
    padding: 3px 10px;
    border-radius: 10px;
    font-size: 10px!important;
}

.auth-modal .modal-dialog-centered{
    display: flex;
    align-items: center;
    margin-top: 15vh;
}

.auth-modal .modal-content{
    height:fit-content
}

.auth-content{
    overflow-y: scroll;
    height: 50vh;
}

.auth-content::-webkit-scrollbar{
    display: none;
}

.auth-content{
    -ms-overflow-style: none; 
    scrollbar-width: none;
}


.top-6rem{
    margin-top: 6rem;
}

.top-1rem{
    margin-top: 1rem!important;
}

.top-2rem{
    margin-top: 2rem;
}

.text-orange{
    color: #F49322!important;
}

.crash-history a:hover {
    color: #FFF !important;
}

.crash-history ul {
    display: flex;
    width: 100%;
    margin: 0;
    padding: 0;
}

.crash-history li {
    font-size: 10px!important;
    display: flex;
    font-weight: 300;
    flex: 1 1;
    align-items: center;
    justify-content: center;
    color: #FFF !important;
    border-radius: 10px;
    text-align: center;
    color: #FFF;
    padding: 2px;
    margin: 0 1px;
    -webkit-transition: all 120ms ease-in;
    transition: all 120ms ease-in;
}

.crash-history li .bg-danger {
	background-color: transparent !important;
    border: 2px solid #304F67;
}

.crash-history .bg-succes-3 {
	box-shadow: none;
}

.crash-history li .bg-danger {
	background-color: transparent !important;
    border: 2px solid #304F67;
}

.crash-history li .bg-succes-3 {
	background-color: transparent !important;
    border: 2px solid #F49322;
}

.width-20{
    width: 20px;
}

.crash-history li:hover {
    /* background: rgb(74 199 236 / 45%) !important; */
    transition: all 0.2s ease-out;
    -o-transition: all 0.2s ease-out;
    -ms-transition: all 0.2s ease-out;
    -moz-transition: all 0.2s ease-out;
}

.history-container{
    padding: 10px;
}

.modal-content .modal-header{
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 13px;
    text-transform: capitalize;
    color: #A7BCCA;
    box-shadow: 0 10px 15px -3px rgb(0 0 0 / 20%), 0 4px 6px -2px rgb(0 0 0 / 10%);
    margin: -1rem;
    padding: 20px;
}

.margin-30{
    margin-top:30px
}

.modal-container{
    
}

.wallet-modal{
    background: #263238;
    border-radius: 10px;
    padding: 10px;
    /* margin: 0 auto; */
}

.font-fam{
    font-family: Inter!important;
}

.MuiTabs-flexContainer{
justify-content:space-between ;
}


.table-head{
    background: #18222A!important;
    color: #fff!important;
    border: none;
    font-size: 0.675rem!important;
    border-bottom: none;
    box-shadow: none;
}
.empty-prompt{
    margin-bottom: 1rem;
    padding: 10px;
    text-transform: capitalize;
    background-color: #1C252C!important;
    border-radius: 5px;
    font-size: 11px!important;
}

.credit-navbar-card{
    border-radius: 10px;
    color: white;
    padding:8px 1px;
    border-top-right-radius: 0;
    border-bottom-right-radius:0 ;
}

.wallet-icon{
    padding:6px;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
}

.nav-icon:hover{
    color: #F49322;
}

.nav-icon{
    color: #FFFFFF;
    padding-top: 10px!important;
}

.bottom-menu{
    color: #A7BCCA!important;
}

.bottom-menu:hover{
    color: #F49322!important;
}

.wallet-card{
    background: linear-gradient(180deg, #263238 0%, rgba(38, 50, 56, 0) 100%);
    border-radius: 10px;
    padding: 15px 10px;
    margin-bottom: 1rem;
}

.item-center{
    display: block;
    margin: auto;
}

.text-green{
    color: #00FF47;
}

.deposit-amount-btn{
    background: #3E4E58;
    border-radius: 5px;
    color: white;
    border: none;
    padding: 5px 8px!important;
}

.deposit-amount-btn:active{
    background: #1f272c;
}

.deposit-amount-btn:hover{
    background: #1f272c;
}

.deposit-input{
    background: #2F3C43;
    border: 1px solid #334253;
    border-radius: 5px;
    padding:10px;
    width: 100%;
    color: white;
    font-size: 12px;
}

.deposit-input:hover{
    background: #313f47!important;
    border: 1px solid #516882!important;
    border-radius: 5px;
    padding:10px;
    width: 100%;
    color: white;
}

.withdraw-input{
    background: #2F3C43!important;
    border: 1px solid #334253!important;
    border-radius: 5px;
    padding:10px;
    color: white;
}

.withdraw-input:hover{
    background: #313f47!important;
    border: 1px solid #516882!important;
    border-radius: 5px;
    padding:10px;
    color: white!important;
}

.green-btn{
    background: #1BB55C;
    border-radius: 5px;
    color: white;
    border: none;
    font-weight: 500;
    padding:12px 10px; 
}

.green-btn:hover{
    background: #127e3f;
    border: none !important;
}

.withdraw-max-btn{
    background: #304F67!important;
    border-radius: 0px 5px 5px 0px!important;
    color: white!important;
    font-size: 10px;
}

.withdraw-table{
    width:100%
}

.withdraw-table tr{
    border-bottom: 1px solid rgba(91, 102, 109, 0.7);
    margin:5px 0px;
}

.withdraw-table tr:first-child{
    border-bottom: none;
    margin:5px 0px;
}

.withdraw-table tr:last-child{
    border-bottom: none;
    margin:5px 0px;
}

.refer-copy-btn{
    background: #2F3C43!important;
    border: 1px solid #334253!important;
    border-radius: 5px;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
}

.refer-card-bg{
    background: linear-gradient(180deg, #2F3C43 0%, rgba(47, 60, 67, 0) 100%);
border-radius: 10px;
padding: 10px;
}

.MuiTab-textColorInherit.Mui-selected{
    background: #1C252C;
    mix-blend-mode: normal;
    border-radius: 5px;
    border: none;
    padding:0px 20px!important;
    margin:auto 5px;
    color: #F49322!important;
}

.MuiTab-root{
    flex-shrink: initial!important;
    min-width: 0px!important;
    color: white;
    min-height: 38px!important;
}

.Mui-selected[aria-selected="true"] .MuiTouchRipple-root{
    border-bottom: none;
}

.MuiTabs-centered{
    justify-content: initial;
}


/* form components */
.input-box{
    background: #263238!important;
    border: 1px solid #4B5863;
    border-radius: 10px;
    color: #FFFFFF!important;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    width: 100%;
    padding: 22px 10px
}

.input-box-error{
    background: #263238!important;
    border: 1px solid #da4921;
    border-radius: 10px;
    color: #FFFFFF!important;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    width: 100%;
    padding: 22px 10px
}

.input-box:hover{
    background: #35464f!important;
    border: 1px solid #798ea0;
}

::placeholder {
    color: rgb(180, 180, 180);
    opacity: 1; /* Firefox */
    font-size: 12px;
  }

.orange-bg{
    background: #F99828;
    border-radius: 5px 5px;
    border: none;
}

.MuiTab-wrapper:hover{
    color:#F49322;
    font-weight: 700;
}

/* logout */
.swal2-container.swal2-backdrop-show, .swal2-container.swal2-noanimation{
    background: #0C1114!important;
    opacity:.98!important;
    border-radius: 10px!important;
}

.swal2-container{
    padding: 2.625em !important;;
}

.user-card-info{
    background: linear-gradient(270deg, #1B262C -29.87%, #051116 113.15%);
    border-radius: 10px;
    padding: 10px;
}

.user-card-level{
    background: linear-gradient(270deg, #66D5FF -29.87%, #263238 113.15%);
    border-radius: 10px;
    padding: 19px;
}

/* progress bar */
.MuiLinearProgress-colorPrimary{
    background-color: #3F525F!important;
}

.MuiLinearProgress-bar{
    border-radius: 5px;
    height: 8px!important;
}

.MuiLinearProgress-root{
    border-radius: 5px;
    height: 8px!important;
}

.MuiLinearProgress-bar1Determinate{
    background-color: #1BB55C!important;
    border-radius: 5px!important;
}

.plays-text{
    color: #A7BCCA!important;
    font-weight: 300;
    padding-top: 10px;
}

.details-card{
    background: #263238;
    border-radius: 10px;
    padding: 10px;
}

.settings-card{
    border-radius: 10px;
    padding: 10px;
}

.settings-input{
    background: #2F3C43!important;
    border: 1px solid #334253!important;
    border-radius: 5px;
    color: white;
}

.chat-card{
    /* background: rgba(47, 62, 72, 0.5); */
    border-radius: 5px;
    padding: 5px;
}

.chat-options{
    background: rgba(86, 115, 135, 0.5);
    border-radius: 5px;
    padding: 3px 13px;
    margin: auto 9px;
}

.chat-options-nobg{
    border-radius: 5px;
    padding: 3px 13px;
    margin: auto 9px;
}

.darker-card{
    background-color: #1C252C;
    padding: 10px;
    border-radius: 5px;
}

.vip-card{
    background-color: #263238;
    padding: 10px;
    border-radius: 5px;
    height:38vh;
    overflow-y: scroll;
}

.table-card-container{
    background-color: #263238;
    padding: 10px;
    border-radius: 10px;
}

.margin-10{
    margin-top: 10px;
}

.width-50{
    width: 50%;
}

.table-stats tr{
    border-bottom: 1px solid #31404A!important;
    margin:3px 0px;
}

.table-stats tr:last-child{
    border-bottom: none!important;
}

.play-btn{
    padding:5px;
    font-size: 11px;
}

.modal-text-head, .text-gray{
    color: #A7BCCA!important;
}

.text-blue{
    color: #92BADA!important;
}

.padding-0{
    padding: 0!important;
}

.MuiTab-root{
    font-family: 'Inter'!important;
}

.confirm-btn{
    background: #1BB55C;
    border-radius: 5px;
    padding: 5px 30px;
    border: none;
    color: #fff;
    font-weight: 500;
    font-size: 13px;
    text-transform: capitalize;
    font-family: Inter;
    font-weight: 600;
}

.cancel-btn{
    background: #aaaaaa;
    border-radius: 5px;
    padding: 5px 30px;
    border: none;
    color: #fff;
    font-weight: 500;
    font-size: 13px;
    text-transform: capitalize;
    font-family: Inter;
    font-weight: 600;
}

.MuiTypography-body1{
    font-family: 'Inter';
}

.notification-icon .badge{
    position: absolute;
    top: -5px;
    right: 20px;
    padding: 3px 7px;
    border-radius: 20%;
    background-color: #F49322;
    color: #1C252C;
    font-weight: 700;
    font-size: 10px!important;
    font-family: 'Inter';
}

.chat-nav .badge{
    position: absolute;
    top: -5px;
    right: 20px;
    padding: 3px 7px;
    border-radius: 20%;
    background-color: #F49322;
    color: #1C252C;
    font-weight: 700;
    font-size: 10px!important;
    font-family: 'Inter';
}

.jackpot-meter{
    background: #132E40;
    border-radius: 5px;
    padding:15px;
}

.jackpot-progress .MuiLinearProgress-bar1Determinate{
    background: #F49322!important;
}

.flex-content{
    display :flex;
}

.input-bg{
    background: #2C3740;
    border-radius: 5px;
    color: white;
    width: 100%;
}

.jackpot-winner-table{
    background: #132E40;
    border-radius: 5px;
    padding: 5px;
}

.jackpot-winner-table th{
    padding: 10px 4px;
    border: none;
    
    
}

.dropdown-up-btn{
    line-height: 15px!important;
    justify-self: center;
    padding: 0.3rem 0.3rem;
}

.header-color{
    color: #A7BCCA!important;
}

.jackpot-winner-table .header-row{
    background: #18222A;
}

.jackpot-winner-table .content-row{
    background: #293C4B;
}

.jackpot-winner-table .content-row:last-child{
    background: #293C4B;
    border-bottom: none;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
}

.text-weight-300{
    font-weight: 300!important;
}

.jackpot-winner-table td{
    padding:6px 10px;
}

.jackpot-winner-table span{
    font-size: 10px!important;
}

.jackpot-hr{
    border: 1px solid #3E4E58;
}

.jackpot-stats .MuiLinearProgress-bar .MuiLinearProgress-root{
    height: 4px!important
}

.align-c{
    align-items: center;
}


.home-logo{
    background-color: red;
    -webkit-mask: url('../images/playa.svg') no-repeat center;
    mask: url('../images/playa.svg') no-repeat center;
}

.logo-style{
    width: 16px;
}

.justify-center{
    justify-content: center;
    display: flex;
}


.rnc__notification-item--danger{
    background: linear-gradient(270deg, #F36740 0%, #402020 103.25%);
    backdrop-filter: blur(2px);
    border-radius: 5px;
    padding:15px;
    width: 100%;
    margin:2px 0px;
    border-left: 0!important;
}

.rnc__notification-item--success{
    border-left: 0!important;
}

.rnc__notification-item--danger .rnc__notification-timer-filler{
    background-color: #c75130;
    height: 5px;
}
.rnc__notification-item--danger .rnc__notification-timer{
    background-color: #fff;
    height: 5px;
}

.rnc__notification-message{
    text-align: left;
    font-family: 'Inter'!important;
    font-size: 13px;
}

.rnc__notification-item--warning .rnc__notification-timer{
    background-color: #fff;
    height: 5px;
}

.rnc__notification-item--warning .rnc__notification-timer-filler{
    background-color: rgb(63, 120, 180);
    height: 5px;
}

.rnc__notification-item--success .rnc__notification-timer{
    background-color: #fff;
    height: 5px;
}

.rnc__notification-item--success .rnc__notification-timer-filler{
    background-color: rgb(63, 120, 180);
    height: 5px;
}

.rnc__notification-item--success{
    background: linear-gradient(90deg, rgba(31, 139, 255, 0.486) 7.79%, rgba(23, 41, 88, 0.513) 96.43%);
    backdrop-filter: blur(28px);
    border-radius: 10px;
    padding:20px;
    width: 100%;
    margin:2px 0px;
}

.rnc__notification-item--warning{
    background: linear-gradient(90deg, rgba(31, 139, 255, 0.486) 7.79%, rgba(23, 41, 88, 0.513) 96.43%);
    backdrop-filter: blur(28px);
    border-radius: 10px;
    padding:15px;
    width: 100%;
    margin:2px 0px;
}

.rnc__notification-item--danger .rnc__notification-close-mark{
    background-color: none;
}

.icon-color{
    color: #00FF47;
}

.btn-orange-darkened{
    background: #a7671e;  
    color: #d6d5d5!important;
}

.font-19{
    font-size: 19px!important;
}

.no-display{
    display: none!important;
}

.show-div{
    display: block;
}

.swal2-loader{
    display: none!important;
}

.swal2-timer-progress-bar{
    background: #F49322;
}

.settings-btn:hover,.settings-btn:active, .settings-btn:focus{
    background-color: #29343a!important;
    color: white;
}

.history-span{
    background-color: #F49322;
    height: calc(2.2rem + 2px);
    font-weight: 700;
}

.MuiLinearProgress-root .MuiLinearProgress-colorPrimary .MuiLinearProgress-determinate .user-info-progress .css-eglki6-MuiLinearProgress-root{
    height: 8px !important;
    width:100%
}

.auth-loader{
    border: 0.3em solid #F49322;
    border-right-color: transparent;
    animation: spinner-border 0.95s linear infinite;
}

.center-loader{
    top:0;
    bottom:0;
    left:0;
    right:0;
}

.prof-link:active{
    background-color: #263238;
}

.sticky-top{
    position: sticky;
    top: 0;
}

.custom-modal-container{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 95%;
    background-color: #1C252C;
    box-shadow: rgb(0 0 0 / 20%) 0px 11px 15px -7px, rgb(0 0 0 / 14%) 0px 24px 38px 3px, rgb(0 0 0 / 12%) 0px 9px 46px 8px;
    padding: 1rem 1rem;
    border-radius: 10px;
}

.custom-modal-container.chatroom-modal{
    width: 100%;
    border-radius: 5px;
}

.MuiModal-backdrop{
    background: rgba(0, 0, 0, 0.92)!important;
}
.css-79ws1d-MuiModal-root{
    display: flex;
    /* bottom: 66px!important; */
    align-items: flex-end;
    z-index: 1040!important;
}

.custom-modal-container.stats-modal-container{
    position: fixed;
    flex-direction: column;
    top: 45vh;
}

.blue-bg{
    background: #1F8BFF;
    border-radius: 3px;
}

.red-bg{
    background: #b44b2b;
    border-radius: 3px;
}

.css-919eu4{
    background: rgba(0, 0, 0, 0.964)!important;
}

.css-8ndowl.stats-container{
    z-index: 1060!important;
    display: flex;
}

.css-79ws1d-MuiModal-root.chat-modal-container{
    display: flex;
    align-items: flex-end;
    z-index: 1240!important;
}

.font-600{
    font-weight: 600!important;
}

.message-input{
    background: #263238!important; 
    color: #fff;
    font-size: 12px!important;
    border: 2px solid #2f4553;
    border-right: none;
    height: 42px!important;
    padding: .5rem;
}

.message-input:hover{
    background: #263238!important;
    border: 2px solid #546771;
    color: #fff!important;
    /* border-right: none; */
}

.btn-send-chat:hover{
    background: #3a4c55!important;
    border: 2px solid #607580;
}

.message-send:hover{
    color: #F49322;
}

.blur-content{
    filter: blur(3px);
}

.css-i9fmh8-MuiBackdrop-root-MuiModal-backdrop{
    z-index: auto!important;
}

.footer-ul{
    padding-inline-start: unset;
    list-style: none;
}

.center-item{
    display: block;
    margin: 0 auto;
}

.flex-end{
    display: flex;
    justify-content: flex-end;
}

.space-between{
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.justify-space{
    display: flex;
    justify-content: space-between;
}

.flex-center{
    display: flex;
    align-items: center;
}

.width-25{
    width: 30px;
    height: 30px;
    padding: 3px 5px;
}

.fa-close:hover{
    color: #0B4664;
}

.modal-heading{
    border-radius: 10px 10px 0px 0px;
    margin: -1rem;
    padding:20px;
    box-shadow: 0 10px 15px -3px rgb(0 0 0 / 20%), 0 4px 6px -2px rgb(0 0 0 / 10%);
}

.avatar-nav{
    width: 37px;
    height: 37px;
    background: #f3c74d!important;
    border-radius: 11%;
    padding: 2px;
}

.font-700{
    font-weight: 700!important;
}

.font-400{
    font-weight: 400;
}

.privacy-row{
    border-top: 1px solid rgba(91, 102, 109, 0.7);;
}

.css-5ryogn-MuiButtonBase-root-MuiSwitch-switchBase.Mui-checked+.MuiSwitch-track{
    background-color: #F49322!important;
}

.MuiSwitch-thumb.css-jsexje-MuiSwitch-thumb{
    background-color: #F49322!important;
}

.css-19gndve{
    background-color: #F49322!important;
}

.css-1nr2wod.Mui-checked + .MuiSwitch-track{
    background-color: #F49322!important;
}

.switch-bg{
    background-color: #354755;
    padding: 5px 14px;
    border-radius: 5px;
}

.flex-column-grid{
    display: flex;
    flex-direction: column!important;
    align-items: center;
}

.btn-edit-avatar{
    margin-top: -16px;
    background: #2f3c43db;
    border: none;
    color: white;
    border-radius: 2px;
    font-size: 11px;
    padding: 5px;
}

.avatar-edit-bg{
    padding:20px
}

.vip-level{
    background: #1C252C!important;
    border-radius: 4px!important;
    color: #8E9AA5!important;
    margin: 5px auto !important;
}

.css-67l5gl{
    background: #1C252C!important;
    border-radius: 4px!important;
    color: #8E9AA5!important;
    margin: 5px auto !important;
}


.vip-accord-content{
    margin:2px 0px!important
}

.css-17o5nyn{
    margin:2px 0px!important
}

.css-17o5nyn.Mui-expanded{
    margin:2px 0px!important
}

.css-1iji0d4.Mui-expanded{
    min-height: 10px!important;
}

.vip-table tr:nth-child(even){
    background-color: #3e535d;
    border-radius: 5px;
}

.vip-table td:first-child{
    border-radius: 5px 0px 0px 5px;
}

.vip-table td:last-child{
    border-radius: 0px 5px 5px 0px;
}

.vip-table td{
    padding: 8px;
}
.vip-table th{
    padding: 8px 0px;
}

.vip-table{
    font-size: 10px!important;
}

.network-status{
    animation: blinker 2s linear infinite;
    width: 10px;
    height: 10px;
    display: inline-block;
    border: 1px solid #00FF47;
    background-color: #00FF47;
    border-radius: 100%;
}

.network-lost{
    animation: blinker 2s linear infinite;
    width: 10px;
    height: 10px;
    display: inline-block;
    border: 1px solid red!important;
    background-color: red!important;
    border-radius: 100%;
}

@keyframes blinker {
    50% {
      opacity: 0.3;
    }
  }


.w-90{
    width: 95%!important;
}

.flex-end{
    justify-content: flex-end;
    display: flex;
    align-items: center;
}

.rotate {
    animation: rotation 1s infinite linear;
}

@keyframes rotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}

.center-div{
    margin: 0;
    position: absolute;
    top: 55%;
    left: 45%;
    transform: translate(-50%, -50%);
}

.margin-bottom-5{
    margin-bottom: 5px!important;
}

.error-border{
    border: 1px solid #CF3E16;
    border-radius: 5px;
    color: #CF3E16;
}

.justify-between{
    justify-content: space-between;
}

.fill-svg-orange path{
    fill: #F49322!important;
}

.fill-gray path{
    fill: #aeb4ce;
}

.width-30{
    width: 30px;
}